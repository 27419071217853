<template>
  <div>
    <page-header
      :title="'Investors'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'investors'"
      :link-url="'/investors'"
    >
      <ca-button :type="'ghost'" class="mr-1" @click.native="exportCSV">Export CSV</ca-button>
      <ca-button @click.native="createUser" >Create Investor </ca-button>
    </page-header>

    <full-page-content-container>
      <!-- User Delete Confirmation Popup   -->
      <ca-popup
        :header="'Delete Investor'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentUser !== null"
      >
        <template v-slot:content>
          Are you sure you want to delete the user?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deleteUser">Yes</ca-button>
        </template>
      </ca-popup>

          <ca-popup
      :header="'Authorize User'"
      :toggle="openVerificationPopup"
      @open="confirmVerify"
      @close="cancelVerify"
    >
      <template v-slot:content>
        Are you sure you want to Authorize this investor?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="verifyUser">Yes</ca-button>
      </template>
    </ca-popup>


      <grid-table
        v-if="rowData.length > 0"
        :column-defs="columnDefs"
        :row-data="rowData"
      >
        <template v-slot:cell-renderer="slotProps">
          <div>
            <span
              class="edit-icon"
              @click="confirmVerify(slotProps.currentRowData)"
              ><img
                :src="require('@/assets/images/check.svg')"
                alt="edit icon"
            /></span>
            <span
              class="edit-icon"
              @click="editUser(slotProps.currentRowData.id)"
              ><img
                :src="require('@/assets/images/icons/edit.svg')"
                alt="edit icon"
            /></span>
            <span
              class="delete-icon"
              @click="confirmDelete(slotProps.currentRowData)"
            >
              <img
                :src="require('@/assets/images/icons/delete.svg')"
                alt="delete icon"
            /></span>
          </div>
        </template>
      </grid-table>
    </full-page-content-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import GridTable from "../../components/tables/GridTable";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import axios from "../../../axios";
import gridTableParentMixin from "../../../mixins/gridTableParentMixin";
import CaPopup from "../../components/popup/CaPopup";
export default {
  mixins: [gridTableParentMixin],
  name: "Users",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    CaPopup,
    FullPageContentContainer,
    GridTable,
    PageHeader,
    CaButton,

  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Company",
          field: "company",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Contact Name", 
          field: "contact_name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Contact Email",
          field: "contact_email",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Authoried",
          field: "is_verified",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Location",
          field: "hq_location",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Currency",
          field: "currency",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Created At",
          type: "date",
          field: "created_at",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/auth/investors`,
      openDeletePopup: false,
      currentUser: null,
      openVerificationPopup: false,
    };
  },
  methods: {
     createUser(){
      this.$router.push({ name: 'create-investor' });
    },
    editUser(userID) {
      this.$router.push({ name: "vu-edit-investor", params: { id: userID } });
      console.log(userID);
    },
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(resourceUrl)
        .then((res) => {
          console.log(res.data.data.investors)
          let data =  res.data.data.investors
          //check if investor is verified or not


          for (let i = 0; i < data.length; i++) {
            if (data[i].is_verified == 1) {
              data[i].is_verified = true;
            } else {
              data[i].is_verified = false;
            }
          }
        // for (let index = 0; index < data.length; index++) {

        //     let investment_stage = data[index].investment_stage.substr(0, 10) + '...';
        //     let startup_stage = data[index].startup_stage?data[index].startup_stage.substr(0, 10) + '...':'';
        //     let investor_type = data[index].investor_type.substr(0, 10) + '...';
        //     // let company = investor.company.substr(0, 10) + '...';
        //     data[index].investment_stage = investment_stage;
        //     data[index].startup_stage = startup_stage;
        //     data[index].investor_type = investor_type;
        // }
         

          
          // console.log(res.data.data)
          this.rowData = data;
          loading.close();

          // this.closePageLoader();
        })
        .catch((err) => {
          loading.close();
          this.$toast(err.response.message, {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
        });
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        this.currentUser = popUpData;
      }
      this.openDeletePopup = true;
    },
    deleteUser() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/user/${this.currentUser.id}`
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `User with id ${this.currentUser.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
    exportCSV(){
      let csvContent = "data:text/csv;charset=utf-8,";
      const date = new Date()
      const FullDate = date.getDate() +'-'+date.getMonth()+'-'+date.getFullYear()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
      const name = "caena-users-"+FullDate+".csv"
      let rowArray = Object.keys(this.rowData[0])
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      this.rowData.forEach(function(data) {
          let rowArray = Object.values(data)
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      });

      

     var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", name);
      document.body.appendChild(link); 

      link.click(); 
    },

    verifyUser() {
      const loading = this.$vs.loading();
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/investor/verify/${this.currentUser.id}`
        )
        .then((res) => {
          this.cancelVerify();
          loading.close();
          this.$toast("User verified successfully", {
            position: "top-center",
            type: "success",
          });
          console.log(res);
          this.setUserInfo(res.data.data);
        })
        .catch((err) => {
          this.cancelVerify();
          loading.close();
          console.log(err);
        });
    },
      confirmVerify(popUpData = null) {
      if (popUpData !== null) {
        this.currentUser = popUpData;
      }
      this.openVerificationPopup = true;
 
      console.log(popUpData);
      console.log(this.currentUser);

    },
    cancelVerify() {
      this.openVerificationPopup = false;
      this.currentUser = null;
    },
  },
  created() {
    this.getTableData(this.dataResourceUrl);
  },
};
</script>

<style scoped>
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}

.content-container {
  padding: 3rem 1.5rem;
  background-color: #ffffff;
}
</style>
